import React, {useState, useEffect} from "react";
import "./style.css";
import {Button} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {getForums} from "../CongressAE2024/BusinessProgram/reducer";
import BusinessProgram, {ForumCard} from "../CongressAE2024/BusinessProgram";
import {fetchForums, FORUMS_PAGE_COUNT} from "../CongressAE2024/BusinessProgram/actions";
import Exponents from "../CongressAE2024/Exponents";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import SadIcon from '../../icons/sad-icon.png';
import Persons from "../Persons";

export default function Bookmarks(props) {

    const { userData, token, mixpanel } = props;

    const [content, setContent] = useState(null);
    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (index, handleChangePage) => {
        setActiveButton(index)
        handleChangePage()
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (mixpanel) {
            mixpanel.track('View. Bookmarks')
        }
    }, []);
    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const handleChangePageProgram = () => {
        setContent(
            <>
                { !token
                    ? <div className='view-bookmarks'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{t("viewBookmarks")}</span>
                      </div>
                    : !navigator.onLine
                        ? <div className='view-bookmarks'>
                            <img src={SadIcon} alt={""}/>
                            <span className='text-bookmark'>{t("viewBookmarksNoInternet")}</span>
                          </div>
                        : <BusinessProgram
                            parentView="Bookmarks"
                            filter={'id in (' + userData.wishlists.forums.join(',') + ')'}
                            {...props}
                        />
                }
            </>
        );
    }

    useEffect(() => {
        handleChangePageProgram();
        setActiveButton(1);
    }, []);

    const handleChangePageExhibits = () => {
        setContent(
            <>
            { !token
                    ? <div className='view-bookmarks'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{t("viewBookmarks")}</span>
                      </div>
                    : !navigator.onLine
                        ? <div className='view-bookmarks'>
                            <img src={SadIcon} alt={""}/>
                            <span className='text-bookmark'>{t("viewBookmarksNoInternet")}</span>
                          </div>
                        : <Exponents
                        bookmarks={'id in (' + userData.wishlists.exponents.join(',') + ')'}
                        {...props}
                        />
                }
            </>
        );
    };
    const handleChangePagePersons = () => {
        setContent(
            <>
                { !token
                    ? <div className='view-bookmarks'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{t("viewBookmarks")}</span>
                    </div>
                    : !navigator.onLine
                        ? <div className='view-bookmarks'>
                            <img src={SadIcon} alt={""}/>
                            <span className='text-bookmark'>{t("viewBookmarksNoInternet")}</span>
                        </div>
                        : <Persons
                            bookmarks={'id in (' + userData.wishlists.exponents.join(',') + ')'}
                            {...props}
                        />
                }
            </>
        );
    };

    return (
        <>
            <div className='tab-buttons'>
                <Button compact id="bookmark-tab-program" className={activeButton === 1 ? 'active' : ''}
                        onClick={() => handleButtonClick(1, handleChangePageProgram)}>{t("program")}</Button>
                <Button compact id="bookmark-tab-persons" className={activeButton === 3 ? 'active' : ''}
                        onClick={() => handleButtonClick(3, handleChangePagePersons)}>{t("persons")}</Button>
                <Button compact id="bookmark-tab-exhibits" className={activeButton === 2 ? 'active' : ''}
                        onClick={() => handleButtonClick(2, handleChangePageExhibits)}>{t("exhibit")}</Button>

            </div>
            <div>{content}</div>
        </>
    );
}
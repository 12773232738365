import React, {useEffect} from 'react';

import {
     Icon, Button,  Image,
} from 'semantic-ui-react';

import {Link} from "react-router-dom";

import "./style.css"
import {useSelector} from "react-redux";

import DescIcon  from '../../icons/desc-congress-icon.png';
import { ReactComponent as ArrowForTicket } from '../../icons/arrow-for-ticket.svg';
import ExpositionIcon  from '../../icons/exposition-icon.png';
import BusIcon  from '../../icons/business-program-icon.png';
import CulIcon  from '../../icons/cultural-program-icon.png';
import ExhibitionIcon  from '../../icons/exhibition-icon.png';

import AsiaMeet from '../../icons/asiameet-icon-for-home.png'
import ArchiveIcon  from '../../icons/archive-events-icon.png';
import EventsIcon  from '../../icons/events-icon.png';
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {toastr} from "react-redux-toastr";
import QrIcon from "../../icons/qr-icon.png";



export default function Home (props) {
    const { navigate, token } = props
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const handleClick = () => {
        if (token) {
            navigate('/scan')
        } else {
            toastr.warning(t("toscanQR"))
        }
    }

      return (
          <>

     <div className="main-block">
            <div className="container-ticket">
              <Link className="ticket" to='/ticket'>
                  <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/ticket-icon.png' className='desc-icon'/>
                  <div className="text-for-ticket">{t('eticket')} <br/> {t('ticket')}</div>

                  <ArrowForTicket className="arrow-icon-1"/>
              </Link>
          </div>
         <div className="main-container">
             <div className="container-cards">
                 <Link className="icontab" to='/description-congress'>
                     <img src={DescIcon}/>
                 </Link>
                 <Link className="label-for-icon" to='/description-congress'>
                     <p className="text-for-icon">{t('descCongress')}</p>
                 </Link>
             </div>

             <div className="container-cards">
                 <Link className="icontab" to='/exposition'>
                     <img src={ExpositionIcon}/>
                 </Link>
                 <Link className="label-for-icon" to='/exposition'>
                     <p className="text-for-icon">{t('exposition')}</p>
                 </Link>
             </div>

             <div className="container-cards">
                 <div className="icontab" onClick={() => navigate('/business-program')}>
                     <img src={BusIcon}/>
                 </div>
                 <div className="label-for-icon" onClick={() => navigate('/business-program')}>
                     <p className="text-for-icon">{t('busProgram')}</p>
                 </div>
             </div>

             <div className="container-cards">
                 <Link className="icontab" to='/cultural-program'>
                     <img src={CulIcon}/>
                 </Link>
                 <Link className="label-for-icon" to='/cultural-program'>
                     <p className="text-for-icon">{t('culProgram')}</p>
                 </Link>
             </div>

             {/*<div className="container-cards">*/}
             {/*    <div className="icontab" onClick={() => navigate('/exponents')}>*/}
             {/*        <img src={ExhibitionIcon}/>*/}
             {/*    </div>*/}
             {/*    <div className="label-for-icon" onClick={() => navigate('/exponents')}>*/}
             {/*        <p className="text-for-icon">{t('exhibitors')}</p>*/}
             {/*    </div>*/}
             {/*</div>*/}

             <div className="container-cards">
                 <Link className="icontab" to='/asiameet'>
                     <img src={AsiaMeet}/>
                 </Link>
                 <Link className="label-for-icon" to='/asiameet'>
                     <p className="text-for-icon">AsiaMeet</p>
                 </Link>
             </div>

             <div className="container-cards">
                 <Link className="icontab" to='/archive-events'>
                     <img src={ArchiveIcon}/>
                 </Link>
                 <Link className="label-for-icon" to='/archive-events'>
                     <p className="text-for-icon">{t('archiveEvents')}</p>
                 </Link>
             </div>
             <div className="container-cards" onClick={() => handleClick()}>
                 <Link className="icontab" to='/scan'>
                     <img src={QrIcon}/>
                 </Link>
                 <Link className="label-for-icon" to='/scan'>
                     <p className="text-for-icon">{t('qrCode')}</p>
                 </Link>
             </div>

         </div>
         {/*<span className='info-ae2025-text'>{t("infoAE2025")}</span>*/}
     </div>
          </>
      );
}
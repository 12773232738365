const translations = {
    ru: {
        main:"Главная",
        eticket:"ВАШ ЭЛЕКТРОННЫЙ",
        ticket: 'БИЛЕТ',
        descCongress:"Описание Конгресса",
        exposition:"Экспозиция",
        busProgram:"Деловая программа",
        culProgram:"Культурно-развлекательная программа",
        culturalProgram: "Культурная программа",
        exhibitors:"Экспоненты",
        driveDirect:"Схема проезда",
        archiveEvents: "Архив мероприятий",
        eventsAE:"Мероприятия АЗИЯЭКСПО",
        persons: "Люди",
        bookmarks:"Закладки",
        profile: "Профиль",
        login: "Войти",
        aboutCongressTitle: "О Конгрессе",
        aboutTheCongressOne: "Международный сельскохозяйственный конгресс ASIAEXPO – это крупнейшая выставочная и деловая площадка для обсуждения современного состояния отрасли и ее дальнейшего развития, формирования трендов, подписания партнерских соглашений и заключения контрактов.",
        aboutTheCongressTwo: "Основной задачей конгресса является развитие сотрудничества в рамках АПК с Китаем, Африкой, Индией, странами Юго-Восточной Азии, Ближнего Востока и СНГ с целью формирования новых каналов экспорта сельскохозяйственной продукции из РФ и импорта передовых технологий из зарубежных стран.",
        congressBlocks: "Блоки Конгресса",
        exh: "Выставка ",
        blockCongressOne: " состоит из 19 отраслевых направлений, где будут представлены более 500 компаний экспонентов мировой сельхоз индустрии из России, Африки, Китая, Индии, Юго-Восточной Азии, Ближнего Востока и стран СНГ. Впервые в индустрии на выставке примут участие хозяйства КРС и МРС и представлен к продаже на экспорт генетический материал от лучших российских предприятий.",
        blockCongressTwo: " состоит из: пленарной сессии с участием представителей власти РФ, Китая, Индии, стран Юго-Восточной Азии, Ближнего Востока и СНГ. 50 тематических секций, где более 300 ведущих экспертов-практиков из различных областей сельского хозяйства поделятся передовым опытом. Обсуждения в формате круглого стола и различных дискуссионных площадок на актуальные темы индустрии.",
        blockCongressThree: " состоит из Церемонии открытия конгресса, Торжественнего вечернего приема в честь открытия конгресса, Футбольного турнира среди участников мероприятия, забега участников ASIAEXPO RUN и турнира по волейболу.",
        dateEvent: "Дата проведения",
        congressHours: "Часы проведения Конгресса для посетителей:",
        locationEvent: "Место проведения",
        oc23_25: "23-25 октября 2024 года.",
        oc23: "23 октября с 10:00 до 18:00",
        oc24: "24 октября с 10:00 до 18:00",
        oc25: "25 октября с 10:00 до 16:00",
        orderPlacement: 'Оформление заказа',
        sirius: "Научно-технологический университет “Сириус”. Краснодарский край, Федеральная территория Сириус, Олимпийский пр-т, 1",
        univerSirius: "Университет «Сириус» был открыт в 2014 г. к XXII зимним Олимпийским играм, как главный Медиацентр олимпиады",
        afterGames: "После Игр на базе медиацентра был основан Парк науки и искусства, где на площади 150 тыс. кв. метров разместились лаборатории для проектной и научно-исследовательской работы, современные лекционные, творческие мастерские, экспозиции и выставки, учебные аудитории и классы.",
        exhComplex: "В настоящее время он является самым крупным конгрессно-выставочным комплексом на территории Юга России и местом проведения крупнейших российских и международных мероприятий: Российского инвестиционного форума, ЕАЭС, Саммита и Экономического форума «Россия – Африка»",
        exposFuture: "Экспозиция появится в ближайшее время. ",
        exposLinks: "Следите за обновлениями в нашем приложении АЕ и подписывайтесь на наши социальные сети!",
        openCeremony: "Церемония открытия Конгресса",
        eventPlace: "Мероприятие пройдет 23 октября на главной сцене Конгресса",
        eventPlace23: "Мероприятие пройдет 25 октября на главной сцене Конгресса",
        openCeremonyText23: "Церемония открытия послужит яркой отправной точкой началом работы Международного сельскохозяйственного конгресса ASIAEXPO 2023. На церемонии выступят с приветственным словом первые лица индустрии, организаторы конгресса и представители иностранных партнеров.",
        openCeremonyText: "Церемония открытия послужит яркой отправной точкой началом работы Международного сельскохозяйственного конгресса ASIAEXPO 2024. На церемонии выступят с приветственным словом первые лица индустрии, организаторы конгресса и представители иностранных партнеров.",
        galaDinner: "Гала-ужин",
        gala23oc: "Мероприятие пройдёт 23 октября в завершении первого дня Конгресса",
        gala25oc: "Мероприятие пройдёт 25 октября в завершении первого дня Конгресса",
        eveningEvent: "Вечернее мероприятие с праздничным фуршетом станет отличным поводом встретиться со своими коллегами и друзьями, наладить новые контакты и провести переговоры в неформальной обстановке в окружении лидеров отрасли.",
        receptionProgram: "Программа приема включает в себя торжественный ужин, выступления артистов, вручение наград и сертификатов участникам конгресса за лучшие научные работы, исследования, инновации и достижения в своей деятельности.",
        footballTour: "Футбольный турнир",
        football25oc: "Мероприятие пройдет 25 октября в завершении последнего дня Конгресса.",
        football27oc: "Мероприятие пройдет 27 октября в завершении последнего дня Конгресса.",
        healthLife: "Мы за спорт и здоровый образ жизни, поэтому решили провести турнир по мини футболу среди команд, состоящих из представителей сельскохозяйственной отрасли!",
        raceAERUN: "Забег ASIAEXPO RUN",
        race24oc: "Мероприятие пройдет 24 октября в завершении первого дня Конгресса.",
        race26oc: "Мероприятие пройдет 26 октября в завершении первого дня Конгресса.",
        joinRace: "Присоединяйтесь к нашему яркому забегу на территории Олимпийского парка! Бегите среди знаковых объектов, таких как стадион \"ФИШТ\" и Олимпийский факел, и получите заряд бодрости и отличного настроения. На финише - церемония награждения самых быстрых участников и много позитивных эмоций!",
        volleyballTour: "Турнир по волейболу",
        volleyball25oc: "Мероприятие пройдет 25 октября в завершении последнего дня Конгресса.",
        showSkills: "Проявите свои навыки и станьте частью уникального соревнования среди участников конгресса! Команды экспонентов будут соревноваться, демонстрируя смекалку и командный дух. Победителей ждут ценные призы!",
        travelPlan: "Схема проезда появится в ближайшее время.",
        travelPlanLinks: "Следите за обновлениями в нашем приложении АЕ и подписывайтесь на наши социальные сети!",
        eventAE: "Пока никаких мероприятий не запланировано.",
        eventAELinks: "Следите за обновлениями в нашем приложении AE и подписывайтесь на наши социальные сети!",
        program: "ПРОГРАММА",
        exhibit: "ЭКСПОНЕНТЫ",
        planned: "Запланированные",
        requests: "Ожидают ответа",
        meetka: "Назначить встречу",
        noPlanned: "Нет запланированных встреч",
        noRequests: "Нет заявок",
        lang: "Язык",
        faq: "Справка",
        techsup: "Тех. поддержка",
        qrCode: "Сканировать QR-код",
        rateApp: "Оцените приложение",
        exit: "Выход",
        editProfile: "Редактировать профиль",
        askQuestion: "Задайте Ваш вопрос нашему специалисту по WhatsApp",
        writeUsMail: "Напишите нам на электронную почту",
        urQuestion: "Ваш вопрос в техническую поддержку",
        send: "Отправить",
        sendingMessage: "Отправка сообщения",
        comment: "Комментарий",
        sendingRate: "Отправка оценки",
        pointCamera: "Наведите камеру на QR код на бейдже участника или на визитную карточку. Считывание произойдет автоматически",
        scanBadge: "Отсканируйте бейдж или визитную карточку",
        pointQR: "наведите на QR код для считывания",
        access: "Запросить разрешение",
        startScan: "Начать сканирование",
        export: "Экспорт",
        toLogApp: "Для входа в приложение введите ваш номер телефона в международном формате",
        sendCode: "Отправить код подтверждения",
        or: "ИЛИ",
        loginOrMail: "Логин или электронная почта",
        password: "Пароль",
        enter: "Войти",
        enter4symb: "Введите четырехзначный код отправленный на номер",
        resend: "Отправить повторно",
        invalidCode: "Неверный код",
        sec: "сек",
        welcome: "Добро пожаловать",
        phoneNumber: "Мобильный телефон",
        notFound: "Ничего не найдено",
        name: "Имя",
        surname: "Фамилия",
        mail: "Почта",
        passportData: "Удостоверяющие данные",
        gender: 'Пол',
        OrganizationName:"Название организации",
        male: 'Мужской',
        female: 'Женский',
        docKind: 'Вид документа',
        serNum: "Серия и Номер",
        departmentCode: "Код подразделения",
        dateIssue: "Дата выдачи",
        regAddress: "Адрес регистрации",
        issuedWhom: "Кем выдан",
        placeBirth: "Место рождения",
        dateBirth: "Дата рождения",
        country: "Страна",
        region: "Регион",
        org: "Организация",
        company: "Компания",
        jobTitle: "Должность",
        validName: "Имя обязательно для заполнения",
        validSurname: "Фамилия обязательна для заполнения",
        validFields: "Пожалуйста, заполните все поля",
        validMailOne: "Email обязателен для заполнения",
        validMailTwo: "Некорректный email",
        validNumber: "Телефон обязателен для заполнения",
        validGender: "Пол обязателен для заполнения",
        validDoc: "Вид документа обязателен для заполнения",
        validSeria: "Серия и Номер обязательны для заполнения",
        validDate: "Дата выдачи обязательна для заполнения",
        validFms: "Кем выдан обязательно для заполнения",
        validBirthAddress: "Место рождения обязательно для заполнения",
        validBirth: "Дата рождения обязательна для заполнения",
        validCountry: "Страна обязательна для заполнения",
        validRegion: "Регион обязателен для заполнения",
        validCompany: "Компания обязательна для заполнения",
        validJobTitle: "Должность обязательна для заполнения",
        validTextQuestion: "Поле обязательно для заполнения",
        validLogin: "Логин обязателен для заполнения",
        validPassword: "Пароль обязателен для заполнения",
        save: "Сохранить",
        policy: "Я согласен с политикой конфиденциальности",
        details: "Подробнее",
        saveData: "Сохранение данных",
        exitWithoutSave: "Вы уверены что хотите выйти без сохранения?",
        selectLang: "Выберите язык",
        paymentMethod:'Выбор способа оплаты',
        congress: "Конгресс",
        ae2023: "АЗИЯЭКСПО 2023",
        ae2024: "АЗИЯЭКСПО 2024",
        look: "Смотреть",
        writeQuestion: "Написать вопрос",
        writeAnswer:'Ответить',
        answer: 'Ваше сообщение',
        uQuestion: "Ваш вопрос",
        rateForum: "Оцените форум",
        speakers: "Спикеры",
        questOnline: "Вопрос онлайн",
        online:'онлайн',
        oc23bp: "23 октября",
        oc24bp: "24 октября",
        oc25bp: "25 октября",
        oc26bp: "26 октября",
        oc27bp: "27 октября",
        loadForums: "Загружаем форумы",
        loadNotifications: "Загружаем уведомления",
        noForums: "Форумы отсутствуют",
        loadExponents: "Загружаем экспонентов",
        noExponents: "Экспоненты отсутствуют",
        loadInfo: "Загружаем информацию",
        notForums: "Форума с таким номером не существует",
        notNotifications: "Уведомления с таким номером не существует",
        notExponents: "Экспонента с таким номером не существует",
        toSendQuestion: "Для возможности отправки Вашего вопроса, пожалуйста, авторизуйтесь",
        viewBookmarks: "Для просмотра закладок Вашего профиля, пожалуйста, авторизуйтесь",
        bookmarkForum: "Для возможности сохранения форума в Ваш профиль, пожалуйста, авторизуйтесь",
        bookmarkExponent: "Для возможности сохранения экспонента в Ваш профиль, пожалуйста, авторизуйтесь",
        toscanQR: "Для возможности сканирования QR-кода, пожалуйста, авторизуйтесь",
        visitor: "Посетитель",
        downloadPDF: "Скачать в PDF",
        segments: "Сегменты",
        lookExposition: "Смотреть на экспозиции",
        appointMeet: "Назначить встречу",
        lookPresentation: "Смотреть презентации компании",
        toWebSite: "Перейти на сайт",
        enterRequest: "Введите запрос",
        segment: "Сегмент",
        notif: "Уведомления",
        tiket: "Билет",
        registration: "Регистрация",
        toReg: "Зарегистрируйтесь для входа в приложение",
        askedQuestions: "Часто задаваемые вопросы",
        policyTitle: "Политика конфиденциальности",
        noPresentations: "К сожалению, презентаций нет",
        timedOut: "Истекло время ожидания ввода кода подтверждения. Пожалуйста, запросите код повторно",
        forgotPass: "Забыли пароль?",
        forgotPassOne: "Забыли свой пароль? Укажите свой Email или номер телефона. На электронную почту вы получите одноразовый код, введите его и новый пароль на следующей странице",
        resetPassword: "Сбросить пароль",
        back: "Назад",
        enterOtp: "Введите одноразовый код и новый пароль",
        otp: "Одноразовый код",
        yes: "Да",
        no: 'Нет',
        delete: 'Удалить',
        newPass: "Новый пароль",
        accept: "Принять",
        notNotif: 'Уведомления станут доступны в ближайшее время, следите за новостями',
        userLogin: "Логин",
        notAuthorized: "Вы не авторизованы",
        scheduleMeet : "Запланировать встречу c",
        personMeet: "Выберите человека",
        dateMeet: "Выберите дату встречи",
        timeMeet: "Выберите время встречи",
        placeMeet: "Выберите место встречи",
        descriptionMeet: "Описание встречи",
        placeholderFioText: "ФИО",
        placeholderPlaceText: "Место встречи",
        placeholderDescriptionText: "Краткое описание",
        btnMeet: "Назначить",
        standMeet: "На вашем стенде",
        otherMeet: "Другое место встречи",
        initiator: "Инициатор",
        responder: "Сотрудник",
        anyEmployee: "Любой сотрудник",
        yourUniqueCode:"Ваш уникальный код",
        customer:"Клиент",
        payment:'Оплата',
        payWndHeader:'Оплата за заказ',
        cancel:"Отмена",
        edit:'Изменить',
        orderValue:"Стоимость заказа",
        delivery:"Доставка",
        inTheWork:'Нужна аккредитация',
        completed:'Аккредитован для посещения Конгресса',
        waitingForPayment:"Ожидает оплату",
        canceled:'Отменён',
        personalData:'Персональные данные',
        youDontHaveTicket:"У вас нет билета.",
        costTicket:'Стоимость проходного билета - 1500р.',
        pleaseLogInAndPay:'Пожалуйста, войдите в систему и оплатите билет ниже с помощью кнопки "Купить билет".',
        pleasePay:'Пожалуйста, оплатите билет ниже с помощью кнопки "Купить билет".',
        buyTicket:"Купить билет",
        required:'обязательно для заполнения',
        invalidEmailFormat:'неверный формат эл.почты',
        lettersSpacesAndHyphens:'допускаются буквы, пробел и дефис',
        enterTheNumberInFull:'введите номер полностью',
        WhoAreYou:"Кем являетесь / должность",
        SelectAnOptionOrContinue:'Выберите вариант или продолжите ввод ...',
        CitySettlement:'Город/поселение',
        Email:'Электронная почта',
        PromoCode:'Промокод',
        CreatingOrder:'Создаём заказ ...',
        apply:'Применить',
        ConfirmOrder:'Подтвердить заказ',
        onTheBankPage:'на странице банка через карту, QR-код, СБЕР',
        bankTransfer:'б/нал на банковский счет',
        byInvoiceDetailsOrQR:'по реквизитам счета или QR-кодом, счет отправим после создания заказа по эл. почте',
        PresentYourCodeAtTheRegistration:'Предъявите Ваш код в зоне регистрации на Конгрессе',
        ASIAEXPOToReceiveYourBadge:'ASIAEXPO 2024, чтобы получить бейдж.',
        unissued:'не оформлен',
        selectPaymentMethod:'Выбрать способ оплаты',
        selectOptionContinue:'Выберите вариант или продолжите ввод ...',
        clearInputFields:'Очистить поля ввода',
        rusSymb: "Некорректные символы недопустимы к вводу",
        successMeet:'Запрос на встречу отправлен. Пожалуйста, дождитесь ее подтверждения',
        createMeet: 'Создание встречи',
        dateMeeting: 'Дата встречи',
        correctMeetTime: 'Время встречи с 9:00 до 18:00',
        viewBookmarksNoInternet: 'Для просмотра закладок Вашего профиля, пожалуйста, подключитесь к интернету',
        startText: 'Начать сканирование',
        stopText: 'Остановить сканирование',
        permissionText: 'Разрешить доступ к камере',
        scanSelector: 'Сканер',
        contactRequest: 'Запросы',
        noNotifications: 'Уведомлений нет',
        message: 'Сообщение: ',
        status: 'Статус сообщения: ',
        recieveDate: 'Дата получения: ',
        messageType: 'Тип сообщения: ',
        faqAsiameet: 'Как назначить встречу?',
        faqAsiameetContent: 'Для того чтобы запланировать встречу, необходимо перейти в карточку нужного Вам экспонента, нажать на кнопку «назначить встречу» и заполнить форму, после этого ваша заявка отобразится на этой странице',
        checkboxAsiameet: 'Показывать завершенные и отмененные встречи',
        contact: 'Контакт',
        addContact: 'Добавить контакт',
        deleteNotify: 'Уведомление успешно удалено',
        deleteNotifyText: 'Вы действительно хотите удалить уведомление?',
        delNotifyHeader: 'Удаление',
        infoAE2025: 'Мы уже начали собирать для вас информацию по Международному сельскохозяйственному конгрессу ASIAEXPO 2025',
    },
    en: {
        main:"Main",
        eticket:"YOUR",
        ticket:"E-TICKET",
        descCongress:"Description of the Congress",
        exposition:"The exposition",
        busProgram:"Business program",
        culProgram:"Cultural and entertainment program",
        culturalProgram: "Cultural program",
        exhibitors:"Exhibitors",
        driveDirect:"Driving directions",
        archiveEvents: "Archive of events",
        eventsAE:"Events ASIAEXPO",
        persons: "Persons",
        bookmarks:"Bookmarks",
        profile: "Account",
        login: "Login",
        orderPlacement: 'Order placement',
        aboutCongressTitle: "About The Congress",
        aboutTheCongressOne: "The ASIAEXPO International Agricultural Congress is the largest exhibition and business platform for discussing the current state of the industry and its further development, shaping trends, signing partnership agreements and concluding contracts.",
        aboutTheCongressTwo: "The main objective of the congress is to develop cooperation within the framework of agriculture with China, Africa, India, countries of Southeast Asia, the Middle East and the CIS in order to form new channels for exporting agricultural products from the Russian Federation and importing advanced technologies from foreign countries.",
        congressBlocks: "Congress Blocks",
        exh: "The exhibition",
        blockCongressOne: " consists of 19 industry areas, where more than 500 exhibiting companies of the global agricultural industry from Russia, Africa, China, India, Southeast Asia, the Middle East and the CIS countries will be represented. For the first time in the industry, cattle and cattle farms will take part in the exhibition and genetic material from the best Russian enterprises will be presented for export.",
        blockCongressTwo: " consists of: a plenary session with representatives of the authorities of the Russian Federation, China, India, Southeast Asia, the Middle East and the CIS. 50 thematic sections, where more than 300 leading experts and practitioners from various fields of agriculture will share best practices. Discussions in the format of a round table and various discussion platforms on current industry topics.",
        blockCongressThree: " consists of the Opening Ceremony of the congress, a solemn evening reception in honor of the opening of the congress, a football tournament among the participants of the event, an ASIAEXPO RUN and a volleyball tournament.",
        dateEvent: "Date of the event",
        congressHours: "Congress opening hours for visitors:",
        locationEvent: "Location of the event",
        oc23_25: "October 23-25, 2024.",
        oc23: "October 23 from 10:00 to 18:00",
        oc24: "October 24 from 10:00 to 18:00",
        oc25: "October 25 from 10:00 to 16:00",
        sirius: "Sirius University of Science and Technology. Krasnodar Territory, Sirius Federal Territory, Olympic Avenue, 1",
        univerSirius: "Sirius University was opened in 2014 for the XXII Winter Olympic Games as the main Media Center of the Olympiad",
        afterGames: "After the Games, the Science and Art Park was founded on the basis of the media center, where laboratories for design and research work, modern lecture halls, creative workshops, expositions and exhibitions, classrooms and classrooms were located on an area of 150 thousand square meters.",
        exhComplex: "Currently, it is the largest convention and exhibition complex in the South of Russia and the venue for major Russian and international events: the Russian Investment Forum, the EAEU, the Summit and the Russia-Africa Economic Forum ",
        exposFuture: "The exposition will appear in the near future.",
        exposLinks: "Stay tuned in our AE app and subscribe to our social networks!",
        openCeremony: "Congress opening ceremony",
        eventPlace: "The event will take place on October 23 on the main stage of the Congress",
        eventPlace23: "The event will take place on October 25 on the main stage of the Congress",
        openCeremonyText23: "The opening ceremony will serve as a bright starting point for the start of the International Agricultural Congress ASIAEXPO 2023. The ceremony will be attended by the first persons of the industry, the organizers of the congress and representatives of foreign partners.",
        openCeremonyText: "The opening ceremony will serve as a bright starting point for the start of the International Agricultural Congress ASIAEXPO 2024. The ceremony will be attended by the first persons of the industry, the organizers of the congress and representatives of foreign partners.",
        galaDinner: "Gala dinner",
        gala23oc: "The event will be held on October 23 at the end of the first day of the Congress",
        gala25oc: "The event will be held on October 25 at the end of the first day of the Congress",
        eveningEvent: "An evening event with a festive buffet will be an excellent opportunity to meet with your colleagues and friends, establish new contacts and hold talks in an informal atmosphere surrounded by industry leaders.",
        receptionProgram: "The reception program includes a gala dinner, performances by artists, presentation of awards and certificates to the participants of the congress for the best scientific works, research, innovations and achievements in their activities.",
        footballTour: "Football tournament",
        football25oc: "The event will be held on October 25 at the end of the last day of the Congress.",
        football27oc: "The event will be held on October 27 at the end of the last day of the Congress.",
        healthLife: "We are for sports and a healthy lifestyle, so we decided to hold a mini football tournament among teams consisting of representatives of the agricultural industry!",
        raceAERUN: "Race ASIAEXPO RUN",
        race24oc: "The event will take place on October 24 at the end of the first day of the Congress.",
        race26oc: "The event will take place on October 26 at the end of the first day of the Congress.",
        joinRace: "Join our vibrant race in the Olympic Park! Run among iconic landmarks such as the FISHT Stadium and the Olympic Torch and feel energized and in a great mood. At the finish line there is an award ceremony for the fastest participants and a lot of positive emotions!",
        volleyballTour: "Volleyball tournament",
        volleyball25oc: "The event will take place on October 25 at the end of the last day of the Congress.",
        showSkills: "Show your skills and become a part of a unique competition among the congress participants! Teams of exhibitors will compete, demonstrating their ingenuity and team spirit. Valuable prizes are waiting for the winners!",
        travelPlan: "The driving directions will appear in the near future.",
        travelPlanLinks: "Stay tuned in our AE app and subscribe to our social networks!\n",
        eventAE: "No events are planned yet.",
        eventAELinks: "Stay tuned in our app and subscribe to our social networks!",
        program: "PROGRAM",
        exhibit: "EXHIBITORS",
        planned: "Planned",
        requests: "Waiting for a response",
        meetka: "Make an appointment",
        noPlanned: "No planned meetings",
        noRequests: "No requests",
        lang: "Language",
        faq: "Help",
        yes: 'Yes',
        no: 'No',
        delete: 'Delete',
        deleteNotifyText: 'Are you sure you want to delete the notification?',
        techsup: "Technical Support",
        qrCode: "Scan the QR code",
        rateApp: "Rate the App",
        exit: "Exit",
        editProfile: "Edit a profile",
        askQuestion: "Ask your question to our WhatsApp specialist",
        writeUsMail: "Write to us by e-mail",
        urQuestion: "Your question to technical support",
        send: "To send",
        sendingMessage: "Sending a message",
        comment: "Comment",
        sendingRate: "Submitting an assessment",
        pointCamera: "Point the camera at the QR code on the participant's badge or visit card. The reading will occur automatically",
        scanBadge: "Scan the badge or visit card",
        pointQR: "and point at the QR code to read it",
        access: "Request permission",
        startScan: "Start scanning",
        export: "Export",
        toLogApp: "To log in to the app, enter your phone number in the international format",
        sendCode: "Send a confirmation code",
        or: "OR",
        loginOrMail: "Login or email address",
        password: "Password",
        enter: "Enter",
        enter4symb: "Enter the four-digit code sent to the number",
        resend: "Resend",
        invalidCode: "Invalid code",
        sec: "sec",
        welcome: "Welcome",
        phoneNumber: "Phone number",
        notFound: "Nothing found",
        name: "Name",
        surname: "Surname",
        mail: "Email",
        passportData: "Сertifying data",
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        docKind: 'Document kind',
        serNum: "Series and Number",
        departmentCode: "Department code",
        dateIssue: "Date of issue",
        regAddress: "Registration address",
        issuedWhom: "Issued by whom",
        placeBirth: "Place of birth",
        dateBirth: "Date of birth",
        country: "A country",
        region: "A region",
        org: "Organization",
        company: "Company",
        jobTitle: "Job title",
        validName: "Name required to fill in",
        validSurname: "Surname required to fill in",
        validFields: "Please, fill out all fields",
        validMailOne: "Email required to fill in",
        validMailTwo: "Incorrect email",
        validNumber: "Phone number required to fill in",
        validCountry: "Country required to fill in",
        validRegion: "Region required to fill in",
        validGender: "Gender required to fill in",
        validDoc: "Document kind required to fill in",
        validSeria: "Series and Number required to fill in",
        validDate: "Date of issue required to fill in",
        validFms: "Issued by whom required to fill in",
        validBirthAddress: "Place of birth required to fill in",
        validBirth: "Date of birth required to fill in",
        validCompany: "Company required to fill in",
        validJobTitle: "Job title required to fill in",
        validTextQuestion: "Field required to fill in",
        validLogin: "Login required to fill in",
        validPassword: "Password required to fill in",
        save: "Save",
        policy: "I agree with the privacy policy",
        details: "More details",
        saveData: "Saving data",
        exitWithoutSave: "Are you sure you want to exit without saving?",
        selectLang: "Select language",
        congress: "Congress",
        ae2023: "ASIAEXPO 2023",
        ae2024: "ASIAEXPO 2024",
        look: "Look",
        writeQuestion: "Write a question",
        uQuestion: "Your question",
        rateForum: "Rate the Forum",
        speakers: "Speakers",
        questOnline: "Question online",
        oc23bp: "October 23",
        oc24bp: "October 24",
        oc25bp: "October 25",
        oc26bp: "October 26",
        oc27bp: "October 27",
        loadForums: "Loading the Forums",
        loadNotifications: "Loading the Notifications",
        noForums: "No forums",
        loadExponents: "Loading the Exhibitors",
        noExponents: "No exhibitors",
        loadInfo: "Loading information",
        notForums: "There is no forum with this number",
        notNotifications: "There is no notification with this number",
        notExponents: "There is no exhibitor with this number",
        toSendQuestion: "To be able to submit your question, please log in",
        viewBookmarks: "To view your profile bookmarks, please log in",
        bookmarkForum: "To be able to save the forum to your profile, please log in",
        bookmarkExponent: "To be able to save the exhibitor to your profile, please log in",
        toscanQR: "To be able to scan the QR code, please log in",
        visitor: "Visitor",
        downloadPDF: "Download in PDF",
        segments: "Segments",
        lookExposition: "Look at the exhibition",
        appointMeet: "Make an appointment",
        lookPresentation: "Watch the company presentations",
        toWebSite: "Go to website",
        enterRequest: "Enter your request",
        segment: "A segment",
        notif: "Notifications",
        tiket: "Ticket",
        registration: "Registration",
        toReg: "Register to log in to the app",
        askedQuestions: "Frequently asked questions",
        policyTitle: "Privacy Policy",
        noPresentations: "Unfortunately, there are no presentations",
        timedOut: "Verification code entry timed out. Please request code again",
        forgotPass: "Forgot your password?",
        forgotPassOne: "Forgot your password? Enter your Email or phone number. You will receive a one-time code by email, enter it and a new password on the next page",
        resetPassword: "Reset password",
        back: "Back",
        enterOtp: "Enter the one-time code and new password",
        otp: "One-time password",
        newPass: "New password",
        accept: "Accept",
        notNotif: 'Notifications will be available soon, stay tuned',
        userLogin: "Login",
        notAuthorized: "You are not authorized",
        scheduleMeet: "Schedule a meeting with",
        personMeet: "Choose a person",
        dateMeet: "Select the date of the meeting",
        timeMeet: "Choose a meeting time",
        placeMeet: "Choose a place",
        descriptionMeet: "Description of the meeting",
        placeholderFioText: "Person",
        placeholderPlaceText: "Placement",
        placeholderDescriptionText: "Short description",
        btnMeet: "Make an appointment",
        standMeet: "At your company stand",
        otherMeet: "Another meeting place",
        initiator: "Initiator",
        responder: "Person",
        anyEmployee: "Any employee",
        yourUniqueCode:"Your unique code",
        customer:"Customer",
        payment:'Payment',
        payWndHeader:'Payment for order',
        cancel:"Cancel",
        edit:'Edit',
        orderValue:"Order value",
        delivery:"Delivery",
        inTheWork:'Accreditation required',
        completed:'Accredited to attend the Congress',
        waitingForPayment:"Waiting for payment",
        canceled:'Canceled',
        personalData:'Personal Data',
        youDontHaveTicket:"You don't have a ticket.",
        costTicket:'The cost of a pass ticket - 1500 ₽',
        pleaseLogInAndPay:'Please log in and pay for your ticket below using the “Buy Ticket” button.',
        pleasePay:'Please pay for your ticket below using the “Buy Ticket” button.',
        buyTicket: "Buy Ticket",
        required:'required',
        invalidEmailFormat:'invalid email format',
        lettersSpacesAndHyphens:'letters, spaces and hyphens are allowed',
        enterTheNumberInFull:'enter the number in full',
        OrganizationName:"Organization name",
        WhoAreYou:'Who are you / position',
        SelectAnOptionOrContinue:'Select an option or continue typing ...',
        CitySettlement:'City/settlement',
        Email:'Email',
        PromoCode:'Promo code',
        CreatingOrder:'Creating an order ...',
        paymentMethod:'Selecting a payment method',
        apply:'Apply',
        ConfirmOrder:'Confirm order',
        online:'online',
        onTheBankPage:'on the bank\'s page via card, QR code, SBER',
        bankTransfer:'bank transfer',
        byInvoiceDetailsOrQR:'by invoice details or QR code, we will send the invoice after creating the order by e-mail',
        PresentYourCodeAtTheRegistration:'Present your code at the registration area of the Congress',
        ASIAEXPOToReceiveYourBadge:'ASIAEXPO 2024 to receive your badge.',
        unissued:'unissued',
        selectPaymentMethod:'Select payment method',
        selectOptionContinue:'Select an option or continue typing ...',
        clearInputFields:'Clear input fields',
        successMeet: 'The meeting request has been sent. Please wait for confirmation.',
        createMeet: 'Creating a meeting',
        rusSymb: "Invalid characters are not allowed to be entered",
        dateMeeting: 'The date of the meeting',
        correctMeetTime: 'The meeting time must be between 9:00 and 18:00',
        noNotifications: 'No notifications',
        writeAnswer:'Answer',
        answer: 'Your message',
        faqAsiameet: 'How to assign a meeting?',
        faqAsiameetContent: 'In order to make an appointment, you need to go to the card of the exhibitor you need, click on the "Make an appointment" button and fill out the form, after which your application will be displayed on this page',
        message: 'Message: ',
        status: 'Message status: ',
        recieveDate: 'Date received: ',
        messageType: 'Message type: ',
        checkboxAsiameet: 'Show completed and cancelled meetings',
        contact: 'Contact',
        addContact: 'Add contact',
        deleteNotify: 'Notification successfully deleted',
        delNotifyHeader: 'Deleting',
        infoAE2025: 'We have already started collecting information for you on the International Agricultural Congress ASIAEXPO 2025',

    },
};

export default translations;

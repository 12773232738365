import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Menu, Input, Form, Button, Modal, FormField, Dropdown} from 'semantic-ui-react';
import config from '../../config/config';
import { openSearch, openAuth, closeSearch } from './actions';
import {isAuthVisible} from './reducer';
import './NavBar.css';
import { getUserDisplayName } from '../UserLogin/reducer';
import { isMobile } from 'react-device-detect';
import { SEARCH_CONTEXTS, setSearchContext } from "../../views/Search/actions";
import { getToContext } from "../../views/Search/reducer";
import { closeMenu } from "../NavBottomBar/actions";

import BookmarkIcon from '../../icons/bookmark-white-icon.png';
import BellIcon from '../../icons/bell-icon.png';
import BellIconNotify from '../../icons/BellIconNotify.png'
import { ReactComponent as BackIcon } from '../../icons/back-icon.svg';
import { ReactComponent as SearchIcon } from '../../icons/search-icon.svg';
import { ReactComponent as FilterIcon } from '../../icons/filter-icon.svg';
import { ReactComponent as GlobalFilter } from '../../icons/global_filter.svg';


import {getForums} from "../../views/CongressAE2024/BusinessProgram/reducer";
import {
    getCurrentSegment,
    getExponentFilter,
    getExponents,
    getPDFExponent,
    getSegments
} from "../../views/CongressAE2024/Exponents/reducer";
import Search from "../../views/Search";
import {useParams} from "react-router";
import Exponents from "../../views/CongressAE2024/Exponents";
import {getCountries} from "../AddressCard/reducer";
import {
    clearCountryFilter,
    clearSearch,
    clearSegmentFilter,
    fetchExponents,
    setCountryFilter,
    setSearch,
    setSegmentFilter
} from "../../views/CongressAE2024/Exponents/action";
import {getLanguage, setLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";
import LanguageIcon from "../../icons/language-icon.png";
import LanguageModal from "../LanguageModal/LanguageModal";
import {setPopupOpen} from "../../views/EditProfile/actions";
import {getOrders} from "../../views/Orders/reducer";
import {isArray} from "lodash";
import {getNotifications} from "../../views/Notifications/reducer";
import {kitcut} from "../WindowDimensions";

export default function NavTopBar(props) {
    const { searchVisible, dispatch, navigate, userData, userLocation, mixpanel, isLoggedIn, location, isPageReloaded } = props;

    const authVisible = useSelector((state) => isAuthVisible(state.navtopbar));
    const userNickName = useSelector((state) => getUserDisplayName(state.userLogin));
    const searchContext = useSelector((state) => getToContext(state.search));
    const forums = useSelector((state) => getForums(state.forums));
    const notifications = useSelector((state) => getNotifications(state.notifications));
    const exponents = useSelector((state) => getExponents(state.exponents));
    const segments = useSelector((state) => getSegments(state.exponents));
    const filterExponents = useSelector((state) => getExponentFilter(state.exponents));
    const segmentTopbar = useSelector((state) => getCurrentSegment(state.exponents));
    const pdf = useSelector((state) => getPDFExponent(state.exponents));
    const orders = useSelector((state)=>getOrders(state.orders))

    const countries = useSelector((state) => getCountries(state.address));

    const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
    const [isFilterDropdownOpenArchive, setFilterDropdownOpenArchive] = useState(false);
    const [searchState, setSearchState] = useState({ search: '', context: searchContext });
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [currentExponentName, setCurrentExponentName] = useState('');
    const [isSearchOpenArchive, setIsSearchOpenArchive] = useState(false)
    const isMainView = location.pathname === '/';
    const isTicketView = location.pathname.startsWith(`/checkout-step-confirm/`)
    const isProfileView = location.pathname === '/profile';
    const isBookmarksView = location.pathname === '/bookmarks';
    const isAsiameetView = location.pathname === '/asiameet';
    const isNotificationsView = location.pathname === '/notifications';
    const isExponentsView = location.pathname === '/archive-events/congress-asiaexpo-2024/exponents';
    const isExponentsArchiveView = location.pathname === '/archive-events/congress-asiaexpo-2023/exponents';
    const isScheduleView = location.pathname === '/schedule';
    const isSearchView = location.pathname.startsWith('/search/');
    const isFormChanged = useSelector((state) => state.formReducer.isFormChanged);

    const languageDispatch = useDispatch();
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];

    const handleLanguageChange = (newLanguage)=>{
        languageDispatch(setLanguage(newLanguage))
    }

    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    useEffect(() => {
        if (location.pathname === "/archive-events/congress-asiaexpo-2024/exponents" && filterExponents.country.length > 0 || filterExponents.segment.length > 0) {
            setFilterDropdownOpen(true);
        }
        if (location.pathname === "/archive-events/congress-asiaexpo-2023/exponents" && filterExponents.country.length > 0 || filterExponents.segment.length > 0) {
            setFilterDropdownOpenArchive(true);
        }
    }, []);


    let mainText = 'Error';
    if (location.pathname === '/')
        mainText = t('main')
    if (location.pathname === '/archive-events/congress-asiaexpo-2024/description-congress')
        mainText = t("descCongress");
    if (location.pathname === '/archive-events/congress-asiaexpo-2024/cultural-program')
        mainText = t("culturalProgram");
    if (location.pathname === '/description-congress')
        mainText = t("descCongress");
    if (location.pathname === '/cultural-program')
        mainText = t("culturalProgram");
    if (location.pathname === '/archive-events')
        mainText = t("archiveEvents");
    if (location.pathname === '/archive-events/congress-asiaexpo-2023')
        mainText = t("congress") + " " + t("ae2023");
    if (location.pathname === '/archive-events/congress-asiaexpo-2024')
        mainText = t("congress") + " " + t("ae2024");
    if (location.pathname === '/archive-events/congress-asiaexpo-2023/cultural-program')
        mainText = t("culturalProgram");

    if (location.pathname === '/archive-events/congress-asiaexpo-2023/business-program-archive')
        mainText = t("busProgram");
    if (location.pathname === '/archive-events/congress-asiaexpo-2023/exponents')
        mainText = t("exhibitors");
    if (location.pathname === '/events-asiaexpo')
        mainText = t("eventsAE");
    if (location.pathname === '/bookmarks')
        mainText = t("bookmarks");
    if (location.pathname === '/archive-events/congress-asiaexpo-2024/exposition' || location.pathname.startsWith('/archive-events/congress-asiaexpo-2024/exposition/'))
        mainText = t("exposition");
    if (location.pathname === '/exposition' || location.pathname.startsWith('/exposition/'))
        mainText = t("exposition");
    if (location.pathname === '/travel-plan')
        mainText = t("driveDirect");
    if (location.pathname === '/profile')
        mainText = t("profile");
    if (location.pathname === '/profile/edit')
        mainText = t("editProfile");
    if (location.pathname === '/scan')
        mainText = t('qrCode');
    if (location.pathname === '/faq')
        mainText = t("faq");
    if (location.pathname === '/support')
        mainText = t('techsup');
    if (location.pathname === '/profile/company')
        mainText = 'Данные о компании';
    if (location.pathname === '/profile/exponents')
        mainText = 'Мои экспоненты';
    if (location.pathname === '/asiameet')
        mainText = 'AsiaMeet';
    if (location.pathname === '/asiameet/form-meeting')
        mainText = t('meetka');
    if (location.pathname === '/notifications')
        mainText = t("notif");
    if (location.pathname === '/archive-events/congress-asiaexpo-2024/exponents')
        mainText = t("exhibitors");
    if (location.pathname === '/archive-events/congress-asiaexpo-2024/business-program')
        mainText = t("busProgram")
    if (location.pathname === '/business-program')
        mainText = t("busProgram")
    if (location.pathname === '/ticket')
        mainText = t("tiket")
    if (location.pathname === '/policy')
        mainText = t("policyTitle")
    if (location.pathname === '/checkout-step-shipping')
        mainText = t('orderPlacement')
    if (location.pathname === '/checkout-step-payment')
        mainText = t("tiket")
    if (location.pathname.startsWith('/archive-events/congress-asiaexpo-2024/exponents/'))
        mainText = segmentTopbar
    if (location.pathname === '/exponent-presentation')
        mainText = pdf.title;
    if (location.pathname === '/persons')
        mainText = t('persons');

    forums.map(function (forum) {
        if (location.pathname === `/archive-events/congress-asiaexpo-2024/business-program/event/${forum.id}`)
            mainText = `${languageString === "en" ? forum.name_en : forum.name}`
        if (location.pathname === `/archive-events/congress-asiaexpo-2023/business-program-archive/event/${forum.id}`)
            mainText = `${languageString === "en" ? forum.name_en : forum.name}`
        if (location.pathname === `/business-program/event/${forum.id}`)
            mainText = `${languageString === "en" ? forum.name_en : forum.name}`
    })

    notifications.map(function (notif) {
        if (location.pathname === `/notifications/${notif.id}`)
            mainText = `${notif.subject}`
    })

    exponents.map(function (exponent) {
        if (location.pathname === `/archive-events/congress-asiaexpo-2024/exponents/exponents-card/${exponent.id}`)
            mainText = `${languageString === "en" ? exponent.name_en : exponent.name}`
        if (location.pathname === `/archive-events/congress-asiaexpo-2023/exponents/exponents-card/${exponent.id}`)
            mainText = `${languageString === "en" ? exponent.name_en : exponent.name}`
    })

    if (isArray(orders))
        orders.map(function (order){
            if (location.pathname === `/checkout-step-confirm/${order.order_key}`)
                mainText = t("tiket")
        })

    if (location.pathname === '/schedule')
        mainText = t("persons")


    useEffect(() => {
        if (!location.pathname.startsWith('/archive-events/congress-asiaexpo-2024/exponents') && !location.pathname.startsWith('/archive-events/congress-asiaexpo-2024/exponents/exponents-card')) {
            setIsSearchOpen(false);
            setIsSearchOpenArchive(false)
        }

        if (location.pathname.startsWith('/archive-events/congress-asiaexpo-2024/exponents/exponents-card')) {
            const exponentId = parseInt(location.pathname.split('/').pop());
            const exponent = exponents.find(c => c.id === exponentId);

            if (exponent) {
                setCurrentExponentName(languageString === "en" ? exponent.name_en : exponent.name);
                setIsSearchOpen(false);
                setIsSearchOpenArchive(false)
            }
        } else {
            setCurrentExponentName('');
        }
        if (!location.pathname.startsWith('/archive-events/congress-asiaexpo-2023/exponents') && !location.pathname.startsWith('/archive-events/congress-asiaexpo-2023/exponents/exponents-card')) {
            setIsSearchOpen(false);
            setIsSearchOpenArchive(false)
        }

        if (location.pathname.startsWith('/archive-events/congress-asiaexpo-2023/exponents/exponents-card')) {
            const exponentId = parseInt(location.pathname.split('/').pop());
            const exponent = exponents.find(c => c.id === exponentId);

            if (exponent) {
                setCurrentExponentName(languageString === "en" ? exponent.name_en : exponent.name);
                setIsSearchOpen(false);
                setIsSearchOpenArchive(false)
            }
        } else {
            setCurrentExponentName('');
        }


        setSearchState({
            search: '',
            context: searchContext,
        });
    }, [location.pathname, searchContext]);

    function handleSubmit() {
        // const matchedCompanies = exponents.filter(c =>
        //     c.name.toLowerCase().includes(searchState.search.toLowerCase())
        // );
        //
        // if (matchedCompanies.length > 0) {
        //     navigate('/exponents/', { state: { results: matchedCompanies } });
        // } else {
        //     navigate('/exponents/', { state: { results: [] } });
        //     console.log('');
        // }
        //
        // dispatch(closeSearch());
        // if (mixpanel) {
        //     mixpanel.track('Search Confirm', { ...userLocation, ...userData, ...searchState });
        // }
        dispatch(clearSearch())
        dispatch(closeSearch());
       // dispatch(setSearchContext(searchState.context));
       // navigate(`/search/${searchState.search}`, {state: {doQuery: true}});
    }

    function handleChangeSearch(e) {
       // console.log('handleChangeSearch' ,e);

        setSearchState({ search: e.target.value });
        if (e.target.value.length >= 3) {
            dispatch(setSearch(e.target.value));
        } else if (e.target.value.length < 3) {
            dispatch(clearSearch());
        }
    }

    function handleOpenSearch() {
        setIsSearchOpen(!isSearchOpen);  // Переключение состояния
        setFilterDropdownOpen(false);
        setFilterDropdownOpenArchive(false);
        setIsSearchOpenArchive(!isSearchOpenArchive)
        dispatch(closeMenu());
        dispatch(clearSegmentFilter())
        dispatch(clearCountryFilter())
    }



    function handleOpenAuth() {
        dispatch(openAuth());
        if (mixpanel) {
            mixpanel.track('Auth Open Form', { ...userLocation, ...userData });
        }
    }

    function handleSelectContext(e, data) {
        setSearchState({ ...searchState, context: data.value });
    }

    const handleFilterOpen = () => {
        setFilterDropdownOpen(!isFilterDropdownOpen)
        setIsSearchOpen(false)
        setIsSearchOpenArchive(false)
        //setFilterDropdownOpenArchive(!isFilterDropdownOpenArchive)
        if (location.pathname === "/archive-events/congress-asiaexpo-2024/exponents" && filterExponents.country.length > 0 || filterExponents.segment.length > 0) {
            setFilterDropdownOpen(true);
        }
        if (location.pathname === "/archive-events/congress-asiaexpo-2023/exponents" && filterExponents.country.length > 0 || filterExponents.segment.length > 0) {
            setFilterDropdownOpenArchive(true);
        }
    }

    const handleFilterOpenArchive = () => {
        setFilterDropdownOpenArchive(!isFilterDropdownOpenArchive)
        setIsSearchOpen(false)
        setIsSearchOpenArchive(false)
      //  setFilterDropdownOpenArchive(!isFilterDropdownOpenArchive)
        if (location.pathname === "/archive-events/congress-asiaexpo-2024/exponents" && filterExponents.country.length > 0 || filterExponents.segment.length > 0) {
            setFilterDropdownOpen(true);
        }
        if (location.pathname === "/archive-events/congress-asiaexpo-2023/exponents" && filterExponents.country.length > 0 || filterExponents.segment.length > 0) {
            setFilterDropdownOpenArchive(true);
        }
    }

    const GlobalFilter = ({ className, alt, children }) => (
        <button className={className} alt={alt}>
            {children}
        </button>
    );

    const filterDropdown = () => (
        <div className="filter-dropdown">
            <div className='container filter-country'>
                <span>{t("country")}</span>
                <ModalCountry/>
            </div>
            <div className='container filter-segment'>
                <span>{t("segment")}</span>
                <ModalSegment/>
            </div>
        </div>
    );

    // exponents.map(function (exponent) {
    //     if (location.pathname === `/exponents/exponents-card/${exponent.id}`)
    //         setIsSearchOpen(false)
    //     if (location.pathname === `/archive-events/congress-asiaexpo-2023/exponents/exponents-card/${exponent.id}`)
    //         setIsSearchOpen(false)
    // })



    const searchForm = () => (
        <>
            <Form onSubmit={handleSubmit} className="search-form-s">
                <input
                    type='search'
                    placeholder={t("enterRequest")}
                    name="search"
                    className="search-input"
                    value={searchState.search}
                     onChange={handleChangeSearch}
                     autoFocus={true}
                    autoComplete="off"

                 >
                     {/*<input className="search-field"/>*/}
                     {/*<Button className="search-button" onClick={() => closeSearchInput()}  type="submit" icon="search"/>*/}
                 </input>
             </Form>
         </>
     );




    const arrayCountries = countries && countries.countries ? Object.values(countries.countries).map(country => ({
        'key': country.iso.toLowerCase(),
        'value': country.iso.toLowerCase(),
        'flag': country.iso.toLowerCase(),
        'text': languageString === "en" ? country.country_en : country.country_ru,
        'states': country.states
    })) : [];

    const arraySegments = segments ? Object.values(segments).map(segment => ({
        'value': segment.term_id,
        'text': languageString === "en" ? segment.name_en : segment.name,
    })) : [];

    const [selectedSegment, setSelectedSegment] = useState('');

    const handleSegmentSelect = (e, { value }) => {
        dispatch(setSegmentFilter(value));
    };

    const handleCountrySelect = (e, { value }) => {
        dispatch(setCountryFilter(value));
    };


    const ModalCountry = () => {
        return (
            <>
                 <Form>
                        <FormField className='filter-field'>
                            <div className='filter-container'>
                                <Dropdown
                                    search
                                    name='country'
                                    selection
                                    className='filter-input'
                                    placeholder={t("country")}
                                    multiple
                                    clearable
                                    noResultsMessage={t("notFound")}
                                    options={arrayCountries}
                                    value={filterExponents.country}
                                    onChange={handleCountrySelect}
                                />
                            </div>
                        </FormField>
                    </Form>
              </>
        )
    }

    const ModalSegment = () => {
        return (
            <>
                <Form>
                    <FormField className='filter-field'>
                        <div className='filter-container'>
                            <Dropdown
                                search
                                name='segment'
                                className='filter-input'
                                placeholder={t("segment")}
                                multiple
                                selection
                                clearable
                                noResultsMessage={t("notFound")}
                                options={arraySegments}
                                value={filterExponents.segment}
                                onChange={handleSegmentSelect}
                            />
                        </div>
                    </FormField>
                </Form>
            </>
        )
    }




    return (
        <>
            <Menu fluid secondary fixed="top" id="nav-top-bar" className="nav-top-bar" color="blue" icon="labeled" inverted>
                {!isMainView && !isProfileView && !isBookmarksView && !isAsiameetView && !isNotificationsView && !isScheduleView
                    ? <BackIcon className="shop-icon back-icon" onClick={() => isFormChanged ? dispatch(setPopupOpen(-1)) : isTicketView ? navigate("/") : navigate(-1)} />
                    : ''
                }


                {isSearchOpen && (
                    <div>
                        {searchForm()}
                    </div>
                )}

                {!isSearchOpen && (
                    <Menu.Item
                        style={{ maxWidth: '752px'}}
                        className={"top-bar-menu-title" + (isMainView ? " first-left" : " second-left")}
                    >
                        <span className='header-title-text'>{currentExponentName || mainText}</span>
                    </Menu.Item>
                )}


                {isExponentsView && (
                    <>
                        <Menu.Item className="shop-icon-item first-right" onClick={handleOpenSearch}>
                            <SearchIcon className="shop-icon"/>
                        </Menu.Item>
                        <Menu.Item className="shop-icon-item second-right" onClick={() => handleFilterOpen()}>
                            <FilterIcon className="shop-icon" />
                        </Menu.Item>
                    </>
                )}

                {isExponentsArchiveView && (
                    <>
                        <Menu.Item className="shop-icon-item first-right" onClick={handleOpenSearch}>
                            <SearchIcon className="shop-icon"/>
                        </Menu.Item>
                        <Menu.Item className="shop-icon-item second-right" onClick={() => handleFilterOpenArchive()}>
                            <FilterIcon className="shop-icon" />
                        </Menu.Item>
                    </>


                )}

                {(location.pathname === '/' || location.pathname === '/profile' || location.pathname === '/support' || location.pathname === '/faq' || location.pathname === '/bookmarks' || location.pathname === '/asiameet' || location.pathname === '/notifications' || location.pathname === "/schedule") && (
                    <>

                        <Menu.Item className="shop-icon-item second-right" onClick={() => navigate("/notifications")}>
                            <img className="shop-icon" src={userData.notifications > 0 ? BellIconNotify : BellIcon} alt="" />
                        </Menu.Item>
                        <Menu.Item className="shop-icon-item first-right">
                            <LanguageModal
                                triggerButton={(
                                    <img className="shop-icon" src={LanguageIcon} alt=""/>
                                )}
                                onLanguageChange={handleLanguageChange}
                                currentLanguage={languageString}
                            />
                        </Menu.Item>
                    </>
                )}
            </Menu>
            {isExponentsView && isFilterDropdownOpen && filterDropdown()}
            {isExponentsArchiveView && isFilterDropdownOpenArchive && filterDropdown()}
        </>

    );
}

import { toastr } from 'react-redux-toastr';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from "../../../config/config";
import {dropToken, receiveUserData, requestUserData} from "../../../components/UserLogin/actions";
import {isOnline} from "../../../components/WindowDimensions";


export const REQUEST_FORUMS = 'REQUEST_FORUMS';
export const RECEIVE_FORUMS = 'RECEIVE_FORUMS';

export const requestForums = () => ({
    type: REQUEST_FORUMS,
});

export const receiveForums = (forums, page = 1) => ({
    type: RECEIVE_FORUMS,
    forums,
    page,
});

export const FORUMS_PAGE_COUNT = 50;

const process_api_error = (json, dispatch) => {
    dispatch(receiveForums([]));
    if (json.code === AUTH_INVALID_TOKEN) {
        dispatch(dropToken());
        toastr.error(AUTH_INVALID_MESSAGE);
    } else
    if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
        toastr.error(json.message);
    else
        toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchForums = (params = {}, lang) => (dispatch) => {
    if (!isOnline(true, params.lang)) {

    } else {

        dispatch(requestForums(params.page ?? 1));

        let url = config.API_FORUMS_URL;
        if (params)
            url += '?'
                + Object.keys(params)
                    .map((k) => k + '=' + encodeURIComponent(params[k]))
                    .join('&');

        return fetch(url)
            .then((response) => response.json())
            .then((json) => {
                if (json.code === 200) {
                    const page = params.id ? 2 : (params.page ?? 1);
                    dispatch(receiveForums(json.data, page));
                } else {
                    process_api_error(json, dispatch);
                }
            })
            .catch((ex) => {
                toastr.error(lang === 'ru' ? 'Ошибка: ' + String(ex) : 'Error: ' + String(ex))
            });
    }
};

export const updateForum = (token, user_id, query, silent = false, lang) => (dispatch) => {
    if (!isOnline(true, lang)) {

    } else {
    dispatch(requestForums(1));

    const headers =  token ? { Authorization: 'Bearer ' + token } : {};
    let url = config.API_UPDATE_FORUM_URL + user_id;

    //console.log('updateForum action', user_id, query, url, token);

    const formData = new FormData();
    formData.append('query', query);

    return fetch(url, { method: 'post', headers, body: formData, })
        .then((response) => response.json())
        .then((json) => {
            // console.log('updateForum responce', json);
            if (json.code === 200) {
                dispatch(receiveForums(json.data, 2));
                if (!silent)
                    toastr.success(`Изменения сохранены`);
            } else {
                process_api_error(json, dispatch);
            }
        })
        .catch((ex) => {
            if (!isOnline(true, lang)) {

            } else {
                toastr.error(lang === 'ru' ? 'Ошибка: ' + String(ex) : 'Error: ' + String(ex))
            }
        });
    }
};

import './style.css';
import React, {useState, useEffect} from "react";
import { ReactComponent as PersonAvatar} from "../../icons/user-icon.svg";
import {Button} from "semantic-ui-react";
import BookmarkComponent from "../../components/Bookmark";
import SadIcon from "../../icons/sad-icon.png";
import InfiniteView from "../../components/InfiniteView";
import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";

export default function Persons(props) {

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    function PersonCard(props) {
        return (
            <div className="persons">
                <div className="person-card">
                    <PersonAvatar width={105} height={105}/>
                    <div className="person-content">
                        <span className="person-name">Иванов Иван</span>
                        <span className="person-location">Россия</span>
                        <span className="person-company">Торгово-производственная компания</span>
                        <span className="person-job-title">Руководитель проектов</span>
                        <span className="person-congress">Экспонент</span>
                        <Button className='meeting-offer-button'>
                            Предложить встречу
                        </Button>
                    </div>
                    <div className='bookmark-box'>
                        <BookmarkComponent
                        bookmark_using_for='exponents'
                        // id={person.id}
                        {...props}
                    />
                    </div>
                </div>
            </div>
        )
    }

    function PersonsList(props) {
        return (
            !navigator.onLine ?
                <div className='view-bookmarks'>
                    <img src={SadIcon} alt={""}/>
                    <span className='text-bookmark'>{t("noExponents")}</span>
                </div>
                : <div className="container exponents filter-opened">
                    {/*{exponents ? exponents.map((element) => (*/}
                    {/*    <ExponentCard*/}
                    {/*        key={element.id}*/}
                    {/*        exponent={element}*/}
                    {/*        showLoader*/}
                    {/*        {...props}*/}
                    {/*    />*/}
                    {/*)) : ''}*/}
                <PersonCard/>
                </div>
        );
    }

    return (
        <>
            <InfiniteView
                dataLength={1}
                // nextFetch={loadMore}
                // hasMore={hasMore}
                dataList={(
                    <PersonsList
                        // exponents={exponents}
                        {...props}
                    />
                )}
                hideEndMessage={true}
                mustAuth={false}
                // isLoading={isFetching}
                loaderText={t("loadExponents") + "..."}
                // zeroText={ !isFetching ? (
                //     <div className='view-bookmarks'>
                //         <img src={SadIcon} alt={""}/>
                //         <span className='text-bookmark'>{t("noExponents")}</span>
                //     </div>
                // ) : ""}
            />
        </>
    )
}